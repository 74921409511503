<template>
  <div>
    <div class="overview">
      <img class="bg" src="@/assets/drainage/bg.png" alt="" />
    </div>
    <div class="bottom">
      <div class="menu">
        <div class="item flex-column-center">
          <img src="@/assets/drainage/menu1.png" alt="" />
          <span>排水管线</span>
        </div>
        <div class="item flex-column-center">
          <img src="@/assets/drainage/menu1.png" alt="" />
          <span>排水管线</span>
        </div>
        <div class="item flex-column-center">
          <img src="@/assets/drainage/menu1.png" alt="" />
          <span>排水管线</span>
        </div>
      </div>
      <div class="bottom-img">
        <img src="@/assets/line.png" alt="" style="width: 100%" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.overview {
  position: relative;
  text-align: center;
  .bg {
    width: 70%;
  }
}
.menu {
  display: flex;
  align-content: center;
  justify-content: center;
  .item {
    gap: 1vh;
    img {
      width: 50%;
    }
  }
}
.bottom-img {
  position: relative;
  top: -3vh;
}
</style>
